import React from "react"

import Layout from "../components/layout"

const PrivacyPage = () => (
  <Layout>
    <div className="light-bg">
      <div className={"container light-bg py-5"} style={{ minHeight: "600px;" }}>
        <u>
          <strong>Deliciae Privacy Policy</strong>
        </u>
        <p>
          This Privacy Policy describes our policies and procedures on the
          collection &amp; use of your information when you use our website and
          tells you about your privacy rights.
        </p>
        <p>
          We use your Personal data to provide and improve the website &amp;
          service. By using the website, you agree to the collection and use of
          information in accordance with this Privacy Policy.
        </p>
        <p>
          While using our website, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you. Personally identifiable information may include, but is
          not limited to: Email address, First name and Last name, Phone number,
          Address, State, Postal code, City.
        </p>
        <p>
          When you access the website by or through a mobile device, we may
          collect certain information automatically, including, but not limited
          to, the type of mobile device you use, your mobile device unique ID,
          the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser you use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that your browser sends whenever you
          visit our website or when you access the website by or through a
          mobile device.
        </p>
        <p>Tracking Technologies used:</p>
        <ul>
          <li>
            <p>
              Cookies or Browser Cookies. Cookies are small files that are
              placed on your computer, mobile device or any other device by a
              website, containing the details of your browsing history on that
              website among its many uses.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Flash Cookies. Certain features of our website may use local
              stored objects to collect and store information about your
              preferences or your activity on our website.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Web Beacons. Certain sections of our website and our emails may
              contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those
              pages or opened an email and for other related website statistics
            </p>
          </li>
        </ul>
        <p>Use of your Personal Data:</p>
        <ul>
          <li>
            <p>
              To provide and maintain our Service, including to monitor the
              usage of our website.
            </p>
          </li>
          <li>
            <p>
              To manage your Account. The Personal Data you provide can give you
              access to different functionalities of the Service that are
              available to you as a registered user.
            </p>
          </li>
          <li>
            <p>
              To contact you by email, telephone calls, SMS, or other equivalent
              forms of electronic communication, informative communications
              related to the functionalities, products.
            </p>
          </li>
          <li>
            <p>
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information.
            </p>
          </li>
          <li>
            <p>
              Advertising. We use your personal information to display
              interest-based ads for features, products, and services that might
              be of interest to you.
            </p>
          </li>
          <li>
            <p>
              To manage your requests: To attend and manage your requests to us.
            </p>
          </li>
          <li>
            <p>
              For other purposes: We may use your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our website, products, services, marketing
              and your experience.
            </p>
          </li>
          <li>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
          </li>
          <li>
            <p>
              The Company will take all steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your Personal Data will take
              place to an organization or a country.
            </p>
          </li>
        </ul>
        <p>Sharing of Personal Data:</p>
        <p>
          We share your information with third parties that collect personally
          identifiable information. They use this information to better target
          users or provide elements of our products &amp; services on behalf of
          us. We use Google Analytics to help us understand how our customers
          use the website. You can read more about how Google uses your Personal
          Information here:
          <a href="https://www.google.com/intl/en/policies/privacy/">
            <u>https://www.google.com/intl/en/policies/privacy/</u>
          </a>
          . You can also opt-out of Google Analytics here:
          <a href="https://tools.google.com/dlpage/gaoptout">
            <u>https://tools.google.com/dlpage/gaoptout</u>
          </a>
          .
        </p>
        <p>Data Retention:</p>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information in our records unless and until you ask us to delete this
          information.
        </p>
        <p>Changes to this Privacy Policy:</p>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>Contact Us:</p>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:{" "}
          <a href="mailto:info@deliciaecakes.com">
            <u>info@deliciaecakes.com</u>
          </a>
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
